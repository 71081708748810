import React from 'react';
import { resetCookieConsentValue } from "react-cookie-consent";

const CookieReset = ({data}) => {
    function resetCookie() {
        resetCookieConsentValue()
        window.location.reload();
    }
    return (
        <div class='cookie-consent-module'>
            <div className='cookie-consent-trigger cookie-consent-trigger-true'><h3>{data.cookieIsAccepted}</h3></div>
            <div className='cookie-consent-trigger cookie-consent-trigger-false'><h3>{data.cookieIsDeclined}</h3></div>
            <div className='cookie-consent-trigger cookie-consent-trigger-undefined'><h3>{data.cookieIsUndefined}</h3></div>
            <button className='btn btn--fill' onClick={resetCookie}>{data.changeBtn}</button>
        </div>
    )
}
export default CookieReset