import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import CookieReset from "../components/cookie-reset";
import TextContent from "../components/text-content";

const PolicyPage = ({ data }) => {
  return (
    <Layout>
        {data.policy.nodes.map(policy => (
            <>
                <HelmetDatoCms 
                    seo={policy.seoMetaTags}
                />
                <style>
                    {`body {background-color: ${policy.backgroundColour.hex}`}
                </style>
                <section className={`page-hero small-hero`}>
                    <div className='content'>
                        <h1>{policy.title}</h1>
                    </div>
                    <div className='scrim'></div>
                    <div 
                    data-sal=""
                    className="image-block">
                        {policy.heroBackground.map(background => (
                            <img className="cover-image" src={background.imageBackground.url} alt='' />
                        ))}
                    </div>
                    <div className='indicator'></div>
                </section>
                <section className="policy-content">
                    {policy.slug === 'cookie-privacy-policy' &&
                        <CookieReset data={data.cookie} />
                    }
                    <TextContent data={policy.content} />
                </section>
            </>
        ))}
    </Layout>
  )
}

export default PolicyPage

export const query = graphql`
    query Policy
    (
    $id: String!) 
    {
        policy: allDatoCmsPolicyPage(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                backgroundColour {
                    hex
                }
                heroBackground {
                    imageBackground {
                        url(imgixParams: {w: "1920"})
                    }
                    imageForeground {
                        url(imgixParams: {w: "1440"})
                    }
                }
                content {
                    blocks {
                        ... on DatoCmsIconContent {
                            ...IconContent
                        }
                        ... on DatoCmsButton {
                            ...ButtonContent
                        }
                    }
                    links
                    value
                }
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
            }
        }
        cookie: datoCmsCookieConsent {
            cookieIsUndefined
            cookieIsDeclined
            cookieIsAccepted
            changeBtn
        }
}
`;